<template>
  <div class="row">
    <div class="offset-lg-4 col-lg-4 alert alert-danger" role="alert">
      Keine Events!
    </div>
  </div>
</template>

<script>
export default {
  name: "NoEventsAlert",
}
</script>

<style scoped>
</style>
