<template>
  <div class="container">
    <div class="row text-center justify-content-center">
      <h1><b><u>JumpWorld Events</u></b></h1>
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">Was sind JumpWorld Events?</h5>
        </div>
        <div class="card-body">
          <h5>Herzlich Willkommen zu den JumpWorld-Events!<br>
            Hier findest du alle Informationen zu allen Jumpworld-Events im Überblick:<br><br>

            Zum Anfang jeden Monats findet ein JumpWorld-Event statt. Es geht darum, die ausgefallensten Ideen oder die kreativsten Sprünge um ein Thema herum zu bauen. Der Spielspaß steht beim Bau des Jump'n'Runs klar im Vordergrund.
            Jede Spielerin und jeder Spieler kann teilnehmen - also auch du!<br><br>

            Natürlich gibt es auch etwas zu gewinnen: Die Erbauer der drei bestplatzierten Jump'n'Runs erwarten einzigartige Preise und Gewinne, mit denen man garantiert zu den coolsten Spielerinnen und Spielern der Lobby gehört!<br><br>

            Am Ende des Monats werden alle Jump'n'Runs von ausgewählten Teamlern unabhängig und fair bewertet.<br><br>

            Des Weiteren hast du die Möglichkeit, dir Daten, Gewinner und andere Statistiken vergangener Events anzusehen.<br><br>

            Wir wünschen dir viel Spaß und Erfolg beim Ausprobieren, Bauen, Springen und Teilnehmen!
          </h5>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-12">
          <h1>Startende Events</h1>
          <div v-if="startingEvents.length === 0">
            <NoEventsAlert/>
          </div>
          <div class="row text-center justify-content-center">
            <div class="col-lg-6 col-md-12 col-12 mb-3" v-for="event in startingEvents"
                 v-bind:key="event.id">
              <EventCard :event="event"></EventCard>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-12">
          <h1>Laufende Events</h1>
          <div v-if="runningEvents.length === 0">
            <NoEventsAlert/>
          </div>
          <div class="row text-center justify-content-center">
            <div class="col-lg-6 col-md-12 col-12 mb-3" v-for="event in runningEvents"
                 v-bind:key="event.id">
              <EventCard :event="event"></EventCard>
            </div>
          </div>
        </div>
      </div>
      <h1>Abgeschlossene Events</h1>
      <div v-if="doneEvents.length === 0">
        <NoEventsAlert/>
      </div>
      <div class="row text-center justify-content-center">
        <div class="col-lg-3 col-md-6 col-12 mb-3" v-for="event in doneEvents" v-bind:key="event.id">
          <EventCard :event="event"></EventCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import jsonEvents from '../assets/events/events.json'
import EventCard from "@/components/Events/EventCard";
import NoEventsAlert from "@/components/Events/NoEventsAlert";

export default {
  name: "Events",
  components: {NoEventsAlert, EventCard},
  created() {
    const currentTime = Math.round(new Date().getTime() / 1000);
    this.startingEvents = this.events.filter(event => event.start > currentTime);
    this.runningEvents = this.events.filter(event => event.start < currentTime && event.end > currentTime);
    this.doneEvents = this.events.filter(event => event.end < currentTime);
  },
  data() {
    return {
      events: jsonEvents,
      startingEvents: {},
      runningEvents: {},
      doneEvents: {},
    }
  }
}
</script>

<style scoped>
.fixedButton {
  position: absolute;
  width: 80%;
  bottom: 15px;
  left: 10%;
}
</style>
