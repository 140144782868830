<template>
  <div class="card h-100">
    <img :src="require(`@/assets/events/img/event_${event.id}.png`)" class="card-img-top" alt="Image of event"/>
    <div class="card-body">
      <h5><span class="badge bg-primary">#{{ event.hashtag}}</span><span v-if="event.createdByTeam" class="badge bg-danger ms-1">#TEAM</span></h5>
      <h5 class="card-title">{{ event.eventName }}</h5>
      <p class="card-text mt-2">{{ event.shortDescription }}</p>
      <router-link :to="'/events/' + event.id" class="btn btn-outline-warning">Weiter zum Event</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "EventCard",
  props: ['event'],
  methods: {
  }
}
</script>

<style scoped>
.bg-primary {
  background-color: #f39c12 !important;
}
</style>
